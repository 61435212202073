import { userInfoFromBackend } from '../../../../lib/api/taps/taps';
import { signUp } from '../../../../lib/api/auth';
import { ReactNode, useEffect, useState } from 'react';
import { loadMetaData } from './utils';
import React from 'react';
import s from './index.module.scss';
import { getStringQueryFromUrl, UrlQueries } from '../../../../utils';

type MainContentP = {
	children: ReactNode;
};

const tele = window?.Telegram.WebApp;

export const MainContent = ({ children }: MainContentP) => {
	const [isLoading, setIsLoading] = useState(true);

	const successLoadingHandler = async () => {
		await Promise.all([userInfoFromBackend()]).then(() => {
			setTimeout(() => setIsLoading(false), 1000);
		});
	};

	const authedPromises = async () => {
		const successFunc = (token: string) => {
			loadMetaData(token, successLoadingHandler);
		};

		const refcode = getStringQueryFromUrl(UrlQueries?.refcode);

		signUp(tele?.initData, successFunc, refcode || undefined);
	};

	useEffect(() => {
		setIsLoading(true);
		authedPromises();
	}, []);

	return (
		<>
			{isLoading && (
				<div className={s.mainWrapper}>
					<img
						className={s.logoImg}
						src={require('../../../../assets/images/LogoLoading.webp')}
					/>
					<img
						className={s.personImg}
						src={require('../../../../assets/images/PersonLoading.webp')}
					/>
					<div className={s.container}>
						<div className={s.loader} />
						<span className={s.loaderText}>Loading</span>
					</div>
				</div>
			)}
			{children}
		</>
	);
};
