import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={16}
		height={16}
		fill='none'
		{...props}
	>
		<path
			fill='#CBCBCC'
			d='M3.923 2.744a.833.833 0 1 0-1.179 1.179L6.821 8l-4.077 4.077a.833.833 0 1 0 1.179 1.179L8 9.179l4.077 4.077a.833.833 0 1 0 1.179-1.179L9.179 8l4.077-4.077a.833.833 0 1 0-1.179-1.179L8 6.821 3.923 2.744Z'
		/>
	</svg>
);

export default SvgComponent;
