import s from './index.module.scss';
import React, { useEffect, useState } from 'react';
import { userSelectors } from '../../store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import { formatNumber, formatNumberThouthands } from '../../utils';
import { updateProfitLevel, userInfoFromBackend } from '../../lib/api/taps/taps';
import classNames from 'classnames';
import { EnergyIconNew, UpgradeModal } from '../../components';

const imageRender = {
	tap: require('../../assets/images/tap.webp'),
	energy: require('../../assets/images/energy.webp'),
	business: require('../../assets/images/business.webp'),
	investment: require('../../assets/images/gold_investment.webp'),
	real_estate: require('../../assets/images/real_estate.webp'),

	business_big: require('../../assets/images/business_big.webp'),
	tap_big: require('../../assets/images/tap_big.webp'),
	energy_big: require('../../assets/images/energy_big.webp'),
	investment_big: require('../../assets/images/investment_big.webp'),
	real_estate_big: require('../../assets/images/real_estate_big.webp')
};

export default function UpgradePage() {
	const { balance, profitLevels, levels, currentLevelId, currentProfitLevels } =
		useSelector(userSelectors?.userInfo);

	const [modal, setModal] = useState<null | string>(null);

	const closeModalHandler = () => setModal(null);

	const [currentProfitLevelsState, setCurrentProfitLevelsState] = useState(currentProfitLevels);

	const onUpdateProfit = (category: string) => {
		updateProfitLevel(category);
	};

	useEffect(() => {
		const interval = setInterval(() => {
			userInfoFromBackend();
		}, 900);

		//Clearing the interval
		// eslint-disable-next-line consistent-return
		return () => clearInterval(interval);
	}, [balance]);

	useEffect(() => {
		setCurrentProfitLevelsState(currentProfitLevels);
	}, [currentProfitLevels, balance]);

	const currentLevelInvestment = profitLevels?.filter(
		(profitLevel) =>
			profitLevel.category === 'investment' &&
			profitLevel.level === currentProfitLevelsState.investment + 1
	)?.[0];
	const isDisabledInvestmentByBalance =
		balance < (currentLevelInvestment?.costForUpdate || 0);

	const isDisabledInvestmentByLevel =
		((currentLevelInvestment?.level && currentLevelInvestment?.level - 1) || 0) >=
			levels.filter((level) => level.id === currentLevelId)?.[0]?.maxProfitLevel ||
		currentProfitLevelsState.investment === 15;

	const currentLevelRealEstate = profitLevels?.filter(
		(profitLevel) =>
			profitLevel.category === 'real_estate' &&
			profitLevel.level === currentProfitLevelsState.real_estate + 1
	)?.[0];

	const isDisabledRealEstateByBalance =
		balance < (currentLevelRealEstate?.costForUpdate || 0);

	const isDisabledRealEstateByLevel =
		((currentLevelRealEstate?.level && currentLevelRealEstate?.level - 1) || 0) >=
			levels.filter((level) => level.id === currentLevelId)?.[0]?.maxProfitLevel ||
		currentProfitLevelsState.real_estate === 15;

	const currentLevelBusiness = profitLevels?.filter(
		(profitLevel) =>
			profitLevel.category === 'business' &&
			profitLevel.level === currentProfitLevelsState.business + 1
	)?.[0];

	const isDisabledBusinessByBalance =
		balance < (currentLevelBusiness?.costForUpdate || 0);

	const isDisabledBusinessByLevel =
		((currentLevelBusiness?.level && currentLevelBusiness?.level - 1) || 0) >=
			levels.filter((level) => level.id === currentLevelId)?.[0]?.maxProfitLevel ||
		currentProfitLevelsState.business === 15;

	const currentLevelTap = profitLevels?.filter(
		(profitLevel) =>
			profitLevel.category === 'tap' &&
			profitLevel.level === currentProfitLevelsState.tap + 1
	)?.[0];

	const isDisabledLevelTapByBalance = balance < (currentLevelTap?.costForUpdate || 0);

	const isDisabledLevelByLevel =
		((currentLevelTap?.level && currentLevelTap?.level - 1) || 0) >=
			levels.filter((level) => level.id === currentLevelId)?.[0]?.maxProfitLevel ||
		currentProfitLevelsState.tap === 15;

	const currentLevelEnergy = profitLevels?.filter(
		(profitLevel) =>
			profitLevel.category === 'energy' &&
			profitLevel.level === currentProfitLevelsState.energy + 1
	)?.[0];

	const isDisabledEnergyByBalance = balance < (currentLevelEnergy?.costForUpdate || 0);

	const isDisabledEnergyByLevel =
		((currentLevelEnergy?.level && currentLevelEnergy?.level - 1) || 0) >=
			levels.filter((level) => level.id === currentLevelId)?.[0]?.maxProfitLevel ||
		currentProfitLevelsState.energy === 15;

	return (
		<>
			<div className={s.container}>
				<div className={s.balanceWrapper}>
					<span>Your balance</span>
					<div className={s.balance}>
						<img src={require('../../assets/images/сoin.png')} />
						<span>{formatNumber(balance) || 0}</span>
					</div>
				</div>
				<div className={s.wrapperEnergyMultitap}>
					<div className={s.energyMultitap}>
						<div className={s.borderEnergyMultitap} />
						<img
							className={s.imgTap}
							src={imageRender.tap}
						/>
						<span>Multitap</span>
						<div className={s.upgradePerHour}>
							<>
								{currentProfitLevelsState.tap === 15 ? (
									<span>You have max level</span>
								) : (
									<>
										<div className={s.topBonusBg}>
											<img
												className={s.imgCoin}
												src={require('../../assets/images/сoin.png')}
											/>
											+1
										</div>
										<span>for tap</span>
									</>
								)}
							</>
						</div>
						<div
							className={classNames(s.costForUpdateWrapper, s.topBlocks, {
								[s.disabled]: isDisabledLevelTapByBalance || isDisabledLevelByLevel
							})}
						>
							{isDisabledLevelByLevel ? (
								<>
									{currentProfitLevelsState.tap === 15 ? (
										<span>Max level</span>
									) : (
										<>
											<img
												className={s.imgCoin}
												src={require('../../assets/images/Lock.png')}
											/>
											<span>Next Level</span>
										</>
									)}
								</>
							) : (
								<>
									<img
										className={s.imgCoin}
										src={require('../../assets/images/сoin.png')}
									/>
									<span onClick={() => setModal('tap')}>
										{formatNumberThouthands(currentLevelTap?.costForUpdate || 0)}
									</span>
								</>
							)}
						</div>
					</div>
					<div className={s.energyMultitap}>
						<div className={s.borderEnergyMultitap} />
						<img
							className={s.imgEnergy}
							src={imageRender.energy}
						/>
						<span>Energy limit</span>
						<div className={s.upgradePerHour}>
							<>
								{currentProfitLevelsState.energy === 15 ? (
									<span>You have max level</span>
								) : (
									<>
										<div className={s.topBonusBg}>
											<EnergyIconNew />
											+500
										</div>
										<span>energy</span>
									</>
								)}
							</>
						</div>
						<div
							className={classNames(s.costForUpdateWrapper, s.topBlocks, {
								[s.disabled]: isDisabledEnergyByBalance || isDisabledEnergyByLevel
							})}
						>
							{isDisabledEnergyByLevel ? (
								<>
									{currentProfitLevelsState.energy === 15 ? (
										<span>Max level</span>
									) : (
										<>
											<img
												className={s.imgCoin}
												src={require('../../assets/images/Lock.png')}
											/>
											<span>Next Level</span>
										</>
									)}
								</>
							) : (
								<>
									<img
										className={s.imgCoin}
										src={require('../../assets/images/сoin.png')}
									/>
									<span onClick={() => setModal('energy')}>
										{formatNumberThouthands(currentLevelEnergy?.costForUpdate || 0)}
									</span>
								</>
							)}
						</div>
					</div>
				</div>

				<span className={s.earningsTitle}>Earnings</span>

				<div className={s.earningsWrapper}>
					<div className={s.earningsWrapperInner}>
						<div className={s.border} />
						<img
							className={s.imgUpdate}
							src={imageRender.business}
						/>
						<div className={s.earning}>
							<span>Business</span>
							<div className={s.upgradePerHour}>
								<>
									{currentProfitLevelsState.business === 15 ? (
										<span>You have max level</span>
									) : (
										<>
											<div>
												<img
													className={s.imgCoin}
													src={require('../../assets/images/сoin.png')}
												/>
												+{formatNumberThouthands(currentLevelBusiness?.profit || 0)}
											</div>
											<span>per hour</span>
										</>
									)}
								</>
							</div>
						</div>
						<div
							className={classNames(s.costForUpdateWrapper, {
								[s.disabled]: isDisabledBusinessByBalance || isDisabledBusinessByLevel
							})}
						>
							{isDisabledBusinessByLevel ? (
								<>
									{currentProfitLevelsState.business === 15 ? (
										<span>Max level</span>
									) : (
										<>
											<img
												className={s.imgCoin}
												src={require('../../assets/images/Lock.png')}
											/>
											<span>Next Level</span>
										</>
									)}
								</>
							) : (
								<>
									<img
										className={s.imgCoin}
										src={require('../../assets/images/сoin.png')}
									/>
									<span onClick={() => setModal('business')}>
										{formatNumberThouthands(currentLevelBusiness?.costForUpdate || 0)}
									</span>
								</>
							)}
						</div>
					</div>
					<div className={s.earningsWrapperInner}>
						<div className={s.border} />
						<img
							className={s.imgUpdate}
							src={imageRender?.real_estate}
						/>
						<div className={s.earning}>
							<span>Real Estate</span>
							<div className={s.upgradePerHour}>
								<>
									{currentProfitLevelsState.real_estate === 15 ? (
										<span>You have max level</span>
									) : (
										<>
											<div>
												<img
													className={s.imgCoin}
													src={require('../../assets/images/сoin.png')}
												/>
												+{formatNumberThouthands(currentLevelRealEstate?.profit || 0)}
											</div>
											<span>per hour</span>
										</>
									)}
								</>
							</div>
						</div>
						<div
							className={classNames(s.costForUpdateWrapper, {
								[s.disabled]: isDisabledRealEstateByBalance || isDisabledRealEstateByLevel
							})}
						>
							{isDisabledRealEstateByLevel ? (
								<>
									{currentProfitLevelsState.real_estate === 15 ? (
										<span>Max level</span>
									) : (
										<>
											<img
												className={s.imgCoin}
												src={require('../../assets/images/Lock.png')}
											/>
											<span>Next Level</span>
										</>
									)}
								</>
							) : (
								<>
									<img
										className={s.imgCoin}
										src={require('../../assets/images/сoin.png')}
									/>
									<span onClick={() => setModal('real_estate')}>
										{formatNumberThouthands(currentLevelRealEstate?.costForUpdate || 0)}
									</span>
								</>
							)}
						</div>
					</div>
					<div className={s.earningsWrapperInner}>
						<div className={s.border} />
						<img
							className={s.imgUpdate}
							src={imageRender.investment}
						/>
						<div className={s.earning}>
							<span>Investment</span>
							<div className={s.upgradePerHour}>
								<>
									{currentProfitLevelsState.investment === 15 ? (
										<span>You have max level</span>
									) : (
										<>
											<div>
												<img
													className={s.imgCoin}
													src={require('../../assets/images/сoin.png')}
												/>
												+{formatNumberThouthands(currentLevelInvestment?.profit || 0)}
											</div>
											<span>per hour</span>
										</>
									)}
								</>
							</div>
						</div>
						<div
							className={classNames(s.costForUpdateWrapper, {
								[s.disabled]: isDisabledInvestmentByBalance || isDisabledInvestmentByLevel
							})}
						>
							{isDisabledInvestmentByLevel ? (
								<>
									{currentProfitLevelsState.investment === 15 ? (
										<span>Max level</span>
									) : (
										<>
											<img
												className={s.imgCoin}
												src={require('../../assets/images/Lock.png')}
											/>
											<span>Next Level</span>
										</>
									)}
								</>
							) : (
								<>
									<img
										className={s.imgCoin}
										src={require('../../assets/images/сoin.png')}
									/>
									<span onClick={() => setModal('investment')}>
										{formatNumberThouthands(currentLevelInvestment?.costForUpdate || 0)}
									</span>
								</>
							)}
						</div>
					</div>
				</div>
			</div>

			<UpgradeModal
				modal={modal}
				closeModalHandler={closeModalHandler}
				onUpdateProfit={onUpdateProfit}
				currentProfitLevelsState={currentProfitLevelsState}
				imageRender={imageRender}
			/>
		</>
	);
}
