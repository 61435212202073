import { userSelectors } from '../../../store/reducers/user/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import s from './index.module.scss';
import { renderPersonsImage } from '../../../utils';

const tele = window?.Telegram?.WebApp;

export default function HeaderComponent() {
	const photo = tele?.initDataUnsafe?.user?.photo_url;
	const { currentLevelId } = useSelector(userSelectors.userInfo);

	return (
		<div className={s.header}>
			<img
				className={s.avatar}
				src={photo ? photo : renderPersonsImage[currentLevelId - 1]}
			/>

			<div className={s.name}>
				{tele?.initDataUnsafe?.user?.first_name || ''}{' '}
				{tele?.initDataUnsafe?.user?.last_name || ''}
			</div>
		</div>
	);
}
