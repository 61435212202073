const BALANCE_PAGE = '/';
const UPGRADE_PAGE = '/upgrade';
const EARN_PAGE = '/earn';
const FRIENDS_PAGE = '/friends';
const TRANSFER_PAGE = '/transfer';
const LEVELS_PAGE = '/levels';

export default {
	BALANCE_PAGE,
	UPGRADE_PAGE,
	EARN_PAGE,
	FRIENDS_PAGE,
	TRANSFER_PAGE,
	LEVELS_PAGE
};
