import { bindActions } from 'utils';
import { actions } from './reducer';

export const {
	increaseBalanceByTap,
	setUserToken,
	setUserEnergy,
	setCurrentUserLevelId,
	setUserBalance,
	setUserProfit,
	setUserTap,
	setLevels,
	setProfitLevels,
	setUserMaxEnergy,
	setTimeProfit,
	setGrabbedTimeProfit,
	setIsUpdateInfo,
	setIsChangedLevel,
	setIsLevelUpgradeFromTap,
	setCurrentUserProfitLevels,
	setReferralCode,
	setIsTappet,
	setFriendsList,
	setFriendsProfit
} = bindActions(actions);
