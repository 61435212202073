export default function formatNumberThouthands(num: number) {
	if (num === undefined || num === null) return;

	const absNum = Math.abs(num);

	if (absNum >= 1e6) {
		// eslint-disable-next-line consistent-return
		return formatFixed(num / 1e6) + 'M';
	} else if (absNum >= 1e3) {
		// eslint-disable-next-line consistent-return
		return formatFixed(num / 1e3) + 'K';
	}
	
	// eslint-disable-next-line consistent-return
	return num.toString();
}

function formatFixed(num: number) {
	// eslint-disable-next-line prefer-const
	let [integerPart, decimalPart] = num.toFixed(3).split('.');

	if (decimalPart) {
		decimalPart = decimalPart.replace(/0+$/, ''); // Remove trailing zeros
	}

	return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
}