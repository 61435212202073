import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path d="M15.2249 10.2742C14.8182 10.9545 14.9481 11.9122 15.7373 11.9848C15.8473 11.9949 15.9586 12 16.0712 12C18.0594 12 19.6712 10.3883 19.6712 8.40005C19.6712 6.41182 18.0594 4.80005 16.0712 4.80005C15.8267 4.80005 15.6818 5.05513 15.7597 5.28689C15.9617 5.88775 16.0712 6.53112 16.0712 7.20005C16.0712 8.32365 15.7623 9.37511 15.2249 10.2742Z" fill="url(#paint0_linear_1498_7054)"/>
		<path d="M19.5963 19.2032C19.6789 19.8608 20.2084 20.4 20.8711 20.4H22.0712C22.7339 20.4 23.2814 19.8591 23.1714 19.2056C23.0815 18.6713 22.9314 18.1476 22.7231 17.6447C22.3613 16.7712 21.8309 15.9775 21.1624 15.3089C20.4938 14.6403 19.7001 14.11 18.8265 13.7481C18.2457 13.5075 17.6371 13.3446 17.0166 13.2624C16.8234 13.2368 16.7216 13.4741 16.8594 13.6118C18.3753 15.1277 19.3319 17.0984 19.5963 19.2032Z" fill="url(#paint1_linear_1498_7054)"/>
		<path d="M15.2249 10.2742C14.8182 10.9545 14.9481 11.9122 15.7373 11.9848C15.8473 11.9949 15.9586 12 16.0712 12C18.0594 12 19.6712 10.3883 19.6712 8.40005C19.6712 6.41182 18.0594 4.80005 16.0712 4.80005C15.8267 4.80005 15.6818 5.05513 15.7597 5.28689C15.9617 5.88775 16.0712 6.53112 16.0712 7.20005C16.0712 8.32365 15.7623 9.37511 15.2249 10.2742Z" stroke="#73777F"/>
		<path d="M19.5963 19.2032C19.6789 19.8608 20.2084 20.4 20.8711 20.4H22.0712C22.7339 20.4 23.2814 19.8591 23.1714 19.2056C23.0815 18.6713 22.9314 18.1476 22.7231 17.6447C22.3613 16.7712 21.8309 15.9775 21.1624 15.3089C20.4938 14.6403 19.7001 14.11 18.8265 13.7481C18.2457 13.5075 17.6371 13.3446 17.0166 13.2624C16.8234 13.2368 16.7216 13.4741 16.8594 13.6118C18.3753 15.1277 19.3319 17.0984 19.5963 19.2032Z" stroke="#73777F"/>
		<path d="M14.1684 7.20002C14.1684 9.85099 12.0194 12 9.3684 12C6.71743 12 4.5684 9.85099 4.5684 7.20002C4.5684 4.54906 6.71743 2.40002 9.3684 2.40002C12.0194 2.40002 14.1684 4.54906 14.1684 7.20002Z" fill="url(#paint2_linear_1498_7054)"/>
		<path d="M17.195 21.0187C17.6309 20.669 17.7819 20.1076 17.7255 19.5516C17.531 17.6354 16.6821 15.8344 15.3081 14.4603C13.7328 12.885 11.5962 14.4 9.3684 14.4C7.14058 14.4 5.00401 12.885 3.4287 14.4603C2.05466 15.8344 1.2058 17.6354 1.01133 19.5516C0.954896 20.1076 1.10587 20.669 1.54178 21.0187C2.42802 21.7298 4.56046 22.8 9.3684 22.8C14.1763 22.8 16.3088 21.7298 17.195 21.0187Z" fill="url(#paint3_linear_1498_7054)"/>
		<path d="M14.1684 7.20002C14.1684 9.85099 12.0194 12 9.3684 12C6.71743 12 4.5684 9.85099 4.5684 7.20002C4.5684 4.54906 6.71743 2.40002 9.3684 2.40002C12.0194 2.40002 14.1684 4.54906 14.1684 7.20002Z" stroke="#73777F"/>
		<path d="M17.195 21.0187C17.6309 20.669 17.7819 20.1076 17.7255 19.5516C17.531 17.6354 16.6821 15.8344 15.3081 14.4603C13.7328 12.885 11.5962 14.4 9.3684 14.4C7.14058 14.4 5.00401 12.885 3.4287 14.4603C2.05466 15.8344 1.2058 17.6354 1.01133 19.5516C0.954896 20.1076 1.10587 20.669 1.54178 21.0187C2.42802 21.7298 4.56046 22.8 9.3684 22.8C14.1763 22.8 16.3088 21.7298 17.195 21.0187Z" stroke="#73777F"/>
		<defs>
		<linearGradient id="paint0_linear_1498_7054" x1="19.0928" y1="4.80005" x2="19.0928" y2="20.4" gradientUnits="userSpaceOnUse">
		<stop stopColor="#73777F"/>
		<stop offset="1" stopColor="#424448"/>
		</linearGradient>
		<linearGradient id="paint1_linear_1498_7054" x1="19.0928" y1="4.80005" x2="19.0928" y2="20.4" gradientUnits="userSpaceOnUse">
		<stop stopColor="#73777F"/>
		<stop offset="1" stopColor="#424448"/>
		</linearGradient>
		<linearGradient id="paint2_linear_1498_7054" x1="9.3684" y1="2.40002" x2="9.3684" y2="22.8" gradientUnits="userSpaceOnUse">
		<stop stopColor="#73777F"/>
		<stop offset="1" stopColor="#424448"/>
		</linearGradient>
		<linearGradient id="paint3_linear_1498_7054" x1="9.3684" y1="2.40002" x2="9.3684" y2="22.8" gradientUnits="userSpaceOnUse">
		<stop stopColor="#73777F"/>
		<stop offset="1" stopColor="#424448"/>
		</linearGradient>
		</defs>
	</svg>
);

export default SvgComponent;
