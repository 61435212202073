import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={16}
		height={16}
		fill='none'
		{...props}
	>
		<path d="M3.92259 2.74408C3.59715 2.41864 3.06952 2.41864 2.74408 2.74408C2.41864 3.06951 2.41864 3.59715 2.74408 3.92259L6.82149 8L2.74408 12.0774C2.41864 12.4028 2.41864 12.9305 2.74408 13.2559C3.06951 13.5814 3.59715 13.5814 3.92259 13.2559L8 9.17851L12.0774 13.2559C12.4028 13.5814 12.9305 13.5814 13.2559 13.2559C13.5814 12.9305 13.5814 12.4028 13.2559 12.0774L9.17851 8L13.2559 3.92259C13.5814 3.59715 13.5814 3.06951 13.2559 2.74408C12.9305 2.41864 12.4028 2.41864 12.0774 2.74408L8 6.82149L3.92259 2.74408Z" fill="#CBCBCC"/>
	</svg>
);

export default SvgComponent;
