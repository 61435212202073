export default function formatNumber(number: number): string {
	// eslint-disable-next-line prefer-const
	let [integerPart, ] = String(number).split('.');
	// let [integerPart, decimalPart] = String(number).split('.');

	if (integerPart.length > 3) {
		integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	// let formattedNumber = integerPart;
	// if (decimalPart !== undefined) {
	// 	formattedNumber += '.' + decimalPart;
	// }

	return integerPart;
}
