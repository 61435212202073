import {
	getUserLevels,
	getProfitLevels,
	getTimeProfit,
	getFriendsList,
	getFriendsProfit
} from '../../../../../lib/api/taps/taps';

export const loadMetaData = async (token: string, setIsLoadedData?: () => void) => {
	if (token) {
		await Promise.all([
			await getUserLevels(),
			await getProfitLevels(),
			await getTimeProfit(),
			await getFriendsProfit(),
			await getFriendsList()
		]).then(() => setIsLoadedData && setIsLoadedData());
	}
};
