import { userSelectors } from '../../store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import { initUtils } from '@telegram-apps/sdk';
import s from './index.module.scss';
import React, { useEffect } from 'react';
import { formatNumberThouthands } from '../../utils';
import { CopyIcon, SuccessToast } from '../../components';
import toast from 'react-hot-toast';
import { getFriendsProfit, getFriendsList } from '../../lib/api/taps/taps';

const img = require('../../assets/images/Friends.webp');

const utils = initUtils();

export default function FriendsPage() {
	const { referralCode, friendsList, friendsProfit, userToken } = useSelector(
		userSelectors?.userInfo
	);

	const referalLink = process.env.REACT_APP_BOT_URL + '?start=refcode__' + referralCode;

	const refreshData = async () =>
		await Promise.all([await getFriendsProfit(), await getFriendsList()]);

	useEffect(() => {
		if (userToken) refreshData();
	}, [userToken]);

	return (
		<div className={s.container}>
			<div className={s.titleBlock}>
				<span>Invite more friends</span>
				<span>and earn more coins</span>
			</div>

			<div className={s.inviteBlock}>
				<div className={s.inviteBlock_top}>
					<div className={s.inviteBlock_top_item}>
						<span>{friendsList?.length}</span>
						<span>Friends invited</span>
					</div>

					<div className={s.inviteBlock_top_item}>
						<span>
							<img src={require('../../assets/images/сoin.png')} />
							{formatNumberThouthands(
								friendsProfit === 'null' ? 0 : Number(friendsProfit)
							)}
						</span>
						<span>Earned</span>
					</div>
				</div>

				<div className={s.inviteBlock_bottom}>
					<div className={s.imgBlock}>
						<img
							className={s.img}
							src={img}
						/>
					</div>

					<div className={s.infoBlock}>
						<span className={s.infoBlock_title}>Get coins for your friends</span>

						<div className={s.infoBlock_sum}>
							<span>
								<img src={require('../../assets/images/сoin.png')} /> 10,000
							</span>
							<span>for one friend</span>
						</div>

						<div className={s.infoBlock_btn}>
							<button
								className={s.redBtn}
								onClick={() => {
									utils.shareURL(
										referalLink,
										'Earn more coins 💸 Exchange earned coins for REAL MONEY 🔥'
									);
								}}
							>
								Invite friends
							</button>

							<button
								onClick={() =>
									navigator.clipboard.writeText(referalLink).then(
										() => {
											toast(
												(t) => (
													<SuccessToast
														t={t}
														text={'Link was copied!'}
													/>
												),
												{ position: 'bottom-center' }
											);
										},
										(err) => {
											console.error('Async: Could not copy text: ', err);
										}
									)
								}
								className={s.copyBtn}
							>
								<CopyIcon />
							</button>
						</div>
					</div>
				</div>
			</div>

			{friendsList?.length > 0 && (
				<div className={s.friendsContainer}>
					<div className={s.friendsBlock}>
						<span className={s.friendsTitle}>Your friends</span>

						<div className={s.friendsList}>
							{friendsList?.map((f, index) => {
								return (
									<>
										<div className={s.friendItem}>
											<img
												alt='avatar'
												src={require('../../assets/images/friendAvatar.webp')}
											/>

											<span className={s.name}>
												{f?.firstName} {f?.lastName}
											</span>

											<div className={s.coins}>
												<img src={require('../../assets/images/сoin.png')} /> +10K
											</div>
										</div>
										{index !== friendsList?.length - 1 && (
											<div className={s.divider}>
												<div className={s.line} />
											</div>
										)}
									</>
								);
							})}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
