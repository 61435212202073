import React, { useEffect, useState } from 'react';
import s from '../index.module.scss';
import { userSelectors } from 'store/reducers/user/selectors';
import { useSelector } from 'react-redux';

type PropsTypes = {
	coord: { x: number; y: number };
	deleteItemHandler: () => void;
	id: number;
};

export default function TapElements(props: PropsTypes) {
	// const { coord, deleteItemHandler, id } = props;
	const { coord } = props;

	const [notDisplay, setNotDisplay] = useState(false);

	const { tap } = useSelector(userSelectors.userInfo);

	const offsetStyle = {
		'--top': (coord?.y - 100) + 'px',
		'--left': coord?.x + 'px'
	} as React.CSSProperties;

	useEffect(() => {
		const timer = setTimeout(() => {
			setNotDisplay(true);
			// deleteItemHandler();
		}
			, 1500);

		return () => clearTimeout(timer);
	}, []);

	if (notDisplay) {
		return null;
	}

	return (
		<div
			style={offsetStyle}
			className={s.taps}
		>
			+{tap}
		</div>
	);
}
