import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={17}
		height={17}
		fill='none'
		{...props}
	>
		<path d="M3.60432 8.2364L10.0559 1.57671C10.5145 1.10337 11.3085 1.51143 11.1906 2.15983L10.5512 5.67658C10.4928 5.9977 10.6755 6.31388 10.9829 6.42366L13.4749 7.31366C13.9229 7.47367 14.064 8.03794 13.744 8.38994L7.56125 15.191C7.10454 15.6933 6.27683 15.2712 6.41531 14.6065L7.20265 10.8273C7.27565 10.4769 7.05892 10.1314 6.71168 10.0446L3.92146 9.34703C3.42331 9.22249 3.24704 8.60521 3.60432 8.2364Z" fill="#FFE194"/>
		<path d="M3.60432 8.2364L10.0559 1.57671C10.5145 1.10337 11.3085 1.51143 11.1906 2.15983L10.5512 5.67658C10.4928 5.9977 10.6755 6.31388 10.9829 6.42366L13.4749 7.31366C13.9229 7.47367 14.064 8.03794 13.744 8.38994L7.56125 15.191C7.10454 15.6933 6.27683 15.2712 6.41531 14.6065L7.20265 10.8273C7.27565 10.4769 7.05892 10.1314 6.71168 10.0446L3.92146 9.34703C3.42331 9.22249 3.24704 8.60521 3.60432 8.2364Z" fill="url(#paint0_linear_482_25688)"/>
		<defs>
		<linearGradient id="paint0_linear_482_25688" x1="8.60834" y1="0" x2="8.60834" y2="17" gradientUnits="userSpaceOnUse">
		<stop stopColor="#F7C619"/>
		<stop offset="1" stopColor="#F78419"/>
		</linearGradient>
		</defs>
	</svg>
);

export default SvgComponent;
