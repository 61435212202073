import { createSlice } from '@reduxjs/toolkit';
import { IUserInitialState, RoutesE } from 'types';
import * as reducers from './reducers';

const initialState: IUserInitialState = {
	selectedRoute: RoutesE.balance,
	currentLevel: undefined,
	currentLevelId: 1,
	balance: 0,
	tap: 1,
	perHour: 60,
	maxEnergy: 1000,
	energy: 0,
	profit: 0,
	timeProfit: undefined,
	referralCode: '',

	userToken: null,
	levels: [],
	profitLevels: [],

	friendsProfit: '',
	friendsList: [],

	isGrabbedProfit: false,
	isUpdateInfo: false,
	isTappet: false,
	isChangedLevel: false,
	isLevelUpgradeFromTap: false,
	currentProfitLevels: {
		business: 0,
		energy: 0,
		investment: 0,
		real_estate: 0,
		tap: 0
	}
};

export const { actions, reducer } = createSlice({
	name: 'user',
	initialState,
	reducers
});
