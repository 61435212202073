import { instance } from '../..//api';
import { AxiosResponsePromise } from '@types';

type SignUp = (params: {}) => AxiosResponsePromise<any>;

interface IAuthService {
	signUp: SignUp;
}

enum AuthEndpoints {
	signUp = '/clicker/auth/register'
}

export const AuthService: IAuthService = {
	signUp: async (params) => await instance.post(AuthEndpoints.signUp, params)
};
