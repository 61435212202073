const renderTapZoneImage = [
	require('../assets/images/tap_zone_level_1.png'),
	require('../assets/images/tap_zone_level_2.webp'),
	require('../assets/images/tap_zone_level_3.webp'),
	require('../assets/images/tap_zone_level_4.webp'),
	require('../assets/images/tap_zone_level_5.webp'),
	require('../assets/images/tap_zone_level_6.webp')
];

export default renderTapZoneImage;
