import React, { useEffect, useState } from 'react';
import s from './index.module.scss';
import cn from 'classnames';
import { userSelectors } from '../../../store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import { formatNumberThouthands } from '../../../utils';
import { EnergyIconNew, ModalCloseIcon } from '../../../components';

type PropsTypes = {
	modal: null | string;
	closeModalHandler: () => void;
	onUpdateProfit: (category: string) => void;
	currentProfitLevelsState: {
		business: number;
		energy: number;
		investment: number;
		real_estate: number;
		tap: number;
	};
	imageRender: {
		tap: any;
		energy: any;
		business: any;
		investment: any;
		real_estate: any;
		business_big: any;

		tap_big: any;
		energy_big: any;
		investment_big: any;
		real_estate_big: any;
	};
};

export const UpgradeModal = (props: PropsTypes) => {
	const {
		modal,
		currentProfitLevelsState,
		imageRender,
		closeModalHandler,
		onUpdateProfit
	} = props;

	const { balance, profitLevels, levels, currentLevelId } = useSelector(
		userSelectors?.userInfo
	);

	const [isOpened, setIsOpened] = useState(false);

	const closeHandler = () => {
		setIsOpened(false);

		setTimeout(() => closeModalHandler(), 200);
	};

	const onUpdateHandler = () => {
		if (modal) {
			onUpdateProfit(modal);
		}

		closeHandler();
	};

	useEffect(() => {
		if (modal) {
			setTimeout(() => setIsOpened(true), 50);
		}
	}, [modal]);

	const currentLevel = profitLevels?.filter(
		(profitLevel) =>
			profitLevel.category === modal &&
			profitLevel.level ===
				currentProfitLevelsState[modal as keyof typeof currentProfitLevelsState] + 1
	)?.[0];

	const isDisabledInvestmentByBalance = balance < (currentLevel?.costForUpdate || 0);

	const isDisabledInvestmentByLevel =
		((currentLevel?.level && currentLevel?.level - 1) || 0) >=
			levels.filter((level) => level.id === currentLevelId)?.[0]?.maxProfitLevel ||
		currentProfitLevelsState[modal as keyof typeof currentProfitLevelsState] === 15;

	const renderTitle = () => {
		switch (modal) {
			case 'tap':
				return 'Multitap';
			case 'energy':
				return 'Energy limit';
			case 'business':
				return 'Business';
			case 'investment':
				return 'Investment';
			case 'real_estate':
				return 'Real Estate';
			default:
				return '';
		}
	};

	const renderDescr = () => {
		switch (modal) {
			case 'tap':
				return 'You will be able to earn +1 coin per tap';
			case 'energy':
				return 'You will be able to add +500 energy';
			case 'business':
				return `You will be able to earn +${formatNumberThouthands(currentLevel?.profit || 0)} coins per hour`;
			case 'investment':
				return `You will be able to earn +${formatNumberThouthands(currentLevel?.profit || 0)} coins per hour`;
			case 'real_estate':
				return `You will be able to earn +${formatNumberThouthands(currentLevel?.profit || 0)} coins per hour`;
			default:
				return '';
		}
	};

	const renderBonus = () => {
		switch (modal) {
			case 'tap':
				return (
					<div className={s.bonusBlock}>
						<div className={s.bonusBg}>
							<img
								className={s.imgCoin}
								src={require('../../../assets/images/сoin.png')}
							/>
							+1
						</div>
						<span>for tap</span>
					</div>
				);
			case 'energy':
				return (
					<div className={s.bonusBlock}>
						<div className={s.bonusBg}>
							<EnergyIconNew />
							+500
						</div>
						<span>energy</span>
					</div>
				);
			default:
				return (
					<div className={s.bonusBlock}>
						<div className={s.bonusBg}>
							<img
								className={s.imgCoin}
								src={require('../../../assets/images/сoin.png')}
							/>
							+{formatNumberThouthands(currentLevel?.profit || 0)}
						</div>
						<span>per hour</span>
					</div>
				);
		}
	};

	return (
		<div
			onClick={({ target, currentTarget }) => {
				if (target === currentTarget) {
					closeHandler();
				}
			}}
			className={cn(s.modalWrapper, { [s.opened]: !!modal })}
		>
			<div className={cn(s.modal, { [s.opened]: isOpened })}>
				<button
					onClick={closeHandler}
					className={s.closeBtn}
				>
					<ModalCloseIcon />
				</button>

				<img
					className={cn(s.img, modal && s[modal?.toString()])}
					src={imageRender[`${modal}_big` as keyof typeof imageRender]}
				/>

				<div className={s.titleDescr}>
					<h3 className={s.title}>{renderTitle()}</h3>
					<span className={s.descr}>{renderDescr()}</span>
				</div>

				<div>{renderBonus()}</div>

				<button
					disabled={isDisabledInvestmentByBalance || isDisabledInvestmentByLevel}
					className={s.button}
					onClick={onUpdateHandler}
				>
					{isDisabledInvestmentByLevel ? (
						<>
							<img src={require('../../../assets/images/Lock.png')} /> Next Level
						</>
					) : (
						<>
							Upgrade <img src={require('../../../assets/images/сoin.png')} />{' '}
							{formatNumberThouthands(currentLevel?.costForUpdate || 0)}
						</>
					)}
				</button>
			</div>
		</div>
	);
};
