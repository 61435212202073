import * as React from 'react';
import { SVGProps } from 'react';

const TapZoneBg = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={375}
		height={630}
		fill='none'
		viewBox='0 0 375 630'
		{...props}
	>
		<g filter='url(#a)'>
			<circle
				cx={187.5}
				cy={306.5}
				r={123.5}
				fill='red'
			/>
		</g>
		<defs>
			<filter
				id='a'
				width={647}
				height={647}
				x={-136}
				y={-17}
				colorInterpolationFilters='sRGB'
				filterUnits='userSpaceOnUse'
			>
				<feFlood
					floodOpacity={0}
					result='BackgroundImageFix'
				/>
				<feBlend
					in='SourceGraphic'
					in2='BackgroundImageFix'
					result='shape'
				/>
				<feGaussianBlur
					result='effect1_foregroundBlur_65_18783'
					stdDeviation={100}
				/>
			</filter>
		</defs>
	</svg>
);

export default TapZoneBg;
