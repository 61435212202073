import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { getAuthToken, removeAuthToken } from '..//browser-storage';
import qs from 'qs';

export const instance: AxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	paramsSerializer: {
		serialize: (params: any) => qs.stringify(params, { arrayFormat: 'repeat' })
	}
});

// request interceptors

instance.interceptors.request.use(
	(config) => {
		const authToken = getAuthToken();

		// @ts-ignore
		if (!authToken) config.headers = {};
		// @ts-ignore
		else config.headers.Authorization = `Bearer ${authToken}`;

		return config;
	},
	(error) => error
);

// response interceptors

instance.interceptors.response.use(
	(config: AxiosResponse) => config,
	(error) => {
		if (error.response.status === 401) {
			removeAuthToken();

			let id = window.setInterval(function() {}, 0);

			while (id--) {
				window.clearInterval(id);
			}
		}

		if (error.response.status === 403) {
			removeAuthToken();

			let id = window.setInterval(function() {}, 0);

			while (id--) {
				window.clearInterval(id);
			}
		}

		throw error;
	}
);

instance.interceptors.response.use(
	(config: AxiosResponse) => config,
	(error) => {
		if (error.response && error.response.status >= 500) console.log('Серверная ошибка');

		throw error;
	}
);
