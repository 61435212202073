import toast from 'react-hot-toast';
import {
	setUserEnergy,
	setUserBalance,
	setUserProfit,
	setUserTap,
	setLevels,
	setProfitLevels,
	setUserMaxEnergy,
	setCurrentUserLevelId,
	setTimeProfit,
	setGrabbedTimeProfit,
	setIsLevelUpgradeFromTap,
	setCurrentUserProfitLevels,
	setReferralCode,
	setIsChangedLevel,
	setIsTappet,
	setFriendsList,
	setFriendsProfit
} from '../../../store/reducers/user/dispatchers';
import { TapsService } from './TapsService';

export const taps = async (initData: any) => {
	try {
		const { data } = await TapsService.taps({
			taps: initData
		});

		if (data) {
			setIsTappet(true);
		}

		if (data?.energy) {
			setUserEnergy(data?.energy);
		}

		if (data?.balance) {
			setUserBalance(data?.balance);
		}

		if (data?.levelId) {
			setCurrentUserLevelId(data?.levelId);
		}

		if (data?.maxEnergy) {
			setUserMaxEnergy(data?.maxEnergy);
		}

		if (data?.tap) {
			setUserTap(data?.tap);
		}

		if (data?.isLevelUpgrade) {
			setIsLevelUpgradeFromTap(data?.isLevelUpgrade);
		}
	} catch (error: any) {
		toast('Network Error!', {
			icon: '❌',
			style: {
				borderRadius: '20px',
				borderColor: 'rgba(216, 220, 235, 0.12)',
				borderWidth: 2,
				background: 'linear-gradient(180deg, #12151C 0%, #050608 100%)',
				color: '#fff',
				width: '100%'
			}
		});
		console.log(error);
	}
};

export const updateProfitLevel = async (initData: string) => {
	try {
		const { data } = await TapsService.updateProfitLevel({ category: initData });

		if (data) {
			const { data: userData } = await TapsService.userDataNew({});

			if (userData?.profit) {
				setUserProfit(userData?.profit);
			}

			if (userData?.tap) {
				setUserTap(userData?.tap);
			}

			if (userData?.balance) {
				setUserBalance(userData?.balance);
			}

			if (userData?.energy) {
				setUserEnergy(userData?.energy);
			}

			if (userData?.maxEnergy) {
				setUserMaxEnergy(userData?.maxEnergy);
			}

			if (userData?.profitLevels) {
				setCurrentUserProfitLevels(userData?.profitLevels);
			}

			if (userData?.levelId) {
				setCurrentUserLevelId(userData?.levelId);
			}
		}
	} catch (error: any) {
		toast('Network Error!', {
			icon: '❌',
			style: {
				borderRadius: '20px',
				borderColor: 'rgba(216, 220, 235, 0.12)',
				borderWidth: 2,
				background: 'linear-gradient(180deg, #12151C 0%, #050608 100%)',
				color: '#fff',
				width: '100%'
			}
		});
		console.log(error);
	}
};

export const userInfoFromBackend = async () => {
	try {
		const { data } = await TapsService.userDataNew({});

		if (data?.profit) {
			setUserProfit(data?.profit);
		}

		if (data?.tap) {
			setUserTap(data?.tap);
		}

		if (data?.balance) {
			setUserBalance(data?.balance);
		}

		if (data?.energy) {
			setUserEnergy(data?.energy);
		}

		if (data?.maxEnergy) {
			setUserMaxEnergy(data?.maxEnergy);
		}

		if (data?.profitLevels) {
			setCurrentUserProfitLevels(data?.profitLevels);
		}

		if (data?.levelId) {
			setCurrentUserLevelId(data?.levelId);
		}

		if (data?.referralCode) {
			setReferralCode(data?.referralCode);
		}

		if (data?.isLevelUpgrade) {
			setIsChangedLevel(data?.isLevelUpgrade);
		}
	} catch (error: any) {
		toast('Network Error!', {
			icon: '❌',
			style: {
				borderRadius: '20px',
				borderColor: 'rgba(216, 220, 235, 0.12)',
				borderWidth: 2,
				background: 'linear-gradient(180deg, #12151C 0%, #050608 100%)',
				color: '#fff',
				width: '100%'
			}
		});
		console.log(error);
	}
};

export const getUserLevels = async () => {
	try {
		const { data } = await TapsService.levels({});

		if (data) {
			setLevels(data);
		}
	} catch (error: any) {
		toast('Network Error!', {
			icon: '❌',
			style: {
				borderRadius: '20px',
				borderColor: 'rgba(216, 220, 235, 0.12)',
				borderWidth: 2,
				background: 'linear-gradient(180deg, #12151C 0%, #050608 100%)',
				color: '#fff',
				width: '100%'
			}
		});
		console.log(error);
	}
};

export const getProfitLevels = async () => {
	try {
		const { data } = await TapsService.profitLevels({});

		if (data) {
			setProfitLevels(data);
		}
	} catch (error: any) {
		toast('Network Error!', {
			icon: '❌',
			style: {
				borderRadius: '20px',
				borderColor: 'rgba(216, 220, 235, 0.12)',
				borderWidth: 2,
				background: 'linear-gradient(180deg, #12151C 0%, #050608 100%)',
				color: '#fff',
				width: '100%'
			}
		});
		console.log(error);
	}
};

export const getTimeProfit = async () => {
	try {
		const { data } = await TapsService.timeProfit({});

		if (data) {
			setTimeProfit(data);
			setGrabbedTimeProfit(false);
		}
	} catch (error: any) {
		toast('Network Error!', {
			icon: '❌',
			style: {
				borderRadius: '20px',
				borderColor: 'rgba(216, 220, 235, 0.12)',
				borderWidth: 2,
				background: 'linear-gradient(180deg, #12151C 0%, #050608 100%)',
				color: '#fff',
				width: '100%'
			}
		});
		console.log(error);
	}
};

export const getFriendsProfit = async () => {
	try {
		const { data } = await TapsService.profitFriends();

		if (data) {
			setFriendsProfit(data?.amount);
		}
	} catch (error: any) {
		toast('Network Error!', {
			icon: '❌',
			style: {
				borderRadius: '20px',
				borderColor: 'rgba(216, 220, 235, 0.12)',
				borderWidth: 2,
				background: 'linear-gradient(180deg, #12151C 0%, #050608 100%)',
				color: '#fff',
				width: '100%'
			}
		});
		console.log(error);
	}
};

export const getFriendsList = async () => {
	try {
		const { data } = await TapsService.friends();

		if (data) {
			setFriendsList(data);
		}
	} catch (error: any) {
		toast('Network Error!', {
			icon: '❌',
			style: {
				borderRadius: '20px',
				borderColor: 'rgba(216, 220, 235, 0.12)',
				borderWidth: 2,
				background: 'linear-gradient(180deg, #12151C 0%, #050608 100%)',
				color: '#fff',
				width: '100%'
			}
		});
		console.log(error);
	}
};
