import React, { useEffect, useState } from 'react';
import { EnergyIcon } from 'components';
import { userSelectors } from '../../../store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import { formatNumber, formatNumberThouthands } from 'utils';
import s from './index.module.scss';
import { setUserEnergy } from '../../../store/reducers/user/dispatchers';

export default function BottomComponent() {
	const { balance, energy, tap, profit, maxEnergy } = useSelector(userSelectors.userInfo);

	const [energ, setEnerg] = useState(1);

	useEffect(() => {
		if (!energy) return;

		if (energy < 0) {
			setEnerg(0);
		} else {
			setEnerg(energy);
		}
	}, [energy]);

	useEffect(() => {
		if (!energy) return;

		const interval = setInterval(() => {
			if (energ < 0) setEnerg(0);

			if (energ < maxEnergy) {
				if (energ + 3 > maxEnergy) {
					setEnerg(maxEnergy);
				} else {
					setEnerg(energ + 3);
					setUserEnergy(energ + 3);
				}
			} else {
				if (energ >= maxEnergy) setEnerg(maxEnergy);
			}
		}, 1000);

		//Clearing the interval
		// eslint-disable-next-line consistent-return
		return () => clearInterval(interval);
	}, [energ, energy]);

	return (
		<div className={s.footer}>
			<div className={s.balance}>
				<img src={require('../../../assets/images/сoin.png')} />
				<span>{formatNumber(balance) || 0}</span>
			</div>

			<div className={s.balanceInfo}>
				<div className={s.infoWrap}>
					<div className={s.infoBorder} />
					<div className={s.infoBlock}>
						<span>+{tap}</span>
						<span>for tap</span>
					</div>
				</div>

				<div className={s.energyWrapper}>
					<div className={s.energyBlock}>
						<EnergyIcon />

						<span>
							{energ} / {maxEnergy}
						</span>
					</div>
				</div>

				<div className={s.infoWrap}>
					<div className={s.infoBorder} />
					<div className={s.infoBlock}>
						<span>{`+${formatNumberThouthands(profit)}` || '0K'}</span>
						<span>in 1 hour</span>
					</div>
				</div>
			</div>
		</div>
	);
}
