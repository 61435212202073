import {
	HeaderComponent,
	LevelProgressComponent,
	TapZoneComponent,
	BottomComponent,
	CloseIcon,
	EnergyIconNew
} from 'components';
import s from './index.module.scss';
import React, { useEffect, useState } from 'react';
import { userSelectors } from '../../store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import {
	setGrabbedTimeProfit,
	setIsChangedLevel,
	setIsLevelUpgradeFromTap
} from '../../store/reducers/user/dispatchers';
import { formatNumber, formatNumberThouthands, renderPersonsImage } from '../../utils';

export default function HomeScreen() {
	const initialValue = {
		balance: 0,
		levelId: 1,
		isLevelUpgrade: false
	};

	const {
		timeProfit,
		isGrabbedProfit,
		levels,
		currentLevelId,
		isLevelUpgradeFromTap,
		isChangedLevel,
		isTappet
	} = useSelector(userSelectors?.userInfo);

	const [timeProfitState, setTimeProfitState] = useState(initialValue);
	const [isClosedModalLevel, setIsClosedModalLevel] = useState(true);
	const [isClosedModalLevelFromTap, setIsClosedModalLevelFromTap] = useState(true);

	useEffect(() => {
		if (!timeProfit) return;

		setTimeProfitState(timeProfit && timeProfit);
	}, [timeProfit]);

	const onClose = () => {
		setGrabbedTimeProfit(true);
		if (timeProfit?.isLevelUpgrade) setIsClosedModalLevel(false);
	};

	const onCloseModalLevel = () => {
		setIsClosedModalLevel(true);
		setIsClosedModalLevelFromTap(true);
		setIsLevelUpgradeFromTap(false);
		setIsChangedLevel(false);
	};

	useEffect(() => {
		setIsClosedModalLevelFromTap(false);
	}, [currentLevelId]);

	return (
		<div className={s.container}>
			<HeaderComponent />

			<LevelProgressComponent />

			<TapZoneComponent />

			<BottomComponent />

			{!isGrabbedProfit && timeProfitState && Number(timeProfitState.balance) >= 1 && (
				<div className={s.modalWrapper}>
					<div className={s.modalBorder} />
					<button
						className={s.modalCloseIcon}
						onClick={() => onClose()}
					>
						<CloseIcon />
					</button>

					<div className={s.container1}>
						<img src={require('../../assets/images/сoin.png')} />
						<span>{formatNumber(timeProfitState.balance) || 0}</span>
					</div>
					<span className={s.youEarn}>You earned while you were away</span>
					<span className={s.divider} />
					<span className={s.threeHours}>
						Log in to the app every 3 hours to collect the accumulated profit.
					</span>

					<button
						className={s.takeProfitButton}
						onClick={() => onClose()}
					>
						Take profit
					</button>
				</div>
			)}

			{((isTappet && !isClosedModalLevelFromTap && isLevelUpgradeFromTap) ||
				(!isClosedModalLevel && isGrabbedProfit && timeProfitState.isLevelUpgrade) || 
				(isChangedLevel && isGrabbedProfit &&  !isClosedModalLevelFromTap)) && (
				<div className={s.modalWrapper}>
					<div className={s.modalBorder} />
					<button
						className={s.modalCloseIcon}
						onClick={() => onCloseModalLevel()}
					>
						<CloseIcon />
					</button>

					<img
						className={s.imgPerson}
						src={renderPersonsImage[currentLevelId - 1]}
					/>

					<span className={s.container1}>
						<span>{levels[(timeProfit && timeProfit.levelId) || 2]?.name}</span>
					</span>

					<span className={s.youEarn}>Congratulations! You&apos;ve got a new level!</span>
					<span className={s.divider} />

					<div className={s.containerLevelUpdate}>
						<div className={s.containerLevelUpdateWrapper}>
							<div className={s.levelUpdateBlock}>
								<img src={require('../../assets/images/сoin.png')} />
								<span>+1</span>
							</div>
							<span>for tap</span>
						</div>

						<div className={s.containerLevelUpdateWrapper}>
							<div className={s.levelUpdateBlock}>
								<EnergyIconNew />
								<span>+500</span>
							</div>
							<span>energy</span>
						</div>

						<div className={s.containerLevelUpdateWrapper}>
							<div className={s.levelUpdateBlock}>
								<img src={require('../../assets/images/сoin.png')} />
								<span>
									{'+' + formatNumberThouthands(levels[currentLevelId - 1]?.profit)}
								</span>
							</div>
							<span>per hour</span>
						</div>
					</div>

					<button
						className={s.takeProfitButton}
						onClick={() => onCloseModalLevel()}
					>
						Continue
					</button>
				</div>
			)}
		</div>
	);
}
