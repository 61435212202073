import { PayloadAction as PA } from '@reduxjs/toolkit';

export type StoreInner = { user: IUserInitialState };

export type SetBalancePA = PA<IUserInitialState['balance']>;
export type SetEnergyPA = PA<IUserInitialState['energy']>;
export type SetUserTokenPA = PA<IUserInitialState['userToken']>;
export type SetProfitPA = PA<IUserInitialState['profit']>;
export type SetCurrentProfitLevelsPA = PA<IUserInitialState['currentProfitLevels']>;
export type SetTapPA = PA<IUserInitialState['tap']>;
export type SetLevelsPA = PA<IUserInitialState['levels']>;
export type SetProfitLevelsPA = PA<IUserInitialState['profitLevels']>;
export type SetMaxEnergyPA = PA<IUserInitialState['maxEnergy']>;
export type SetTimeProfitPA = PA<IUserInitialState['timeProfit']>;
export type SetGrabbedTimeProfitPA = PA<IUserInitialState['isGrabbedProfit']>;
export type SetCurrentLevelIdPA = PA<IUserInitialState['currentLevelId']>;
export type SetIsTappetPA = PA<IUserInitialState['isTappet']>;
export type SetIsChangedLevelPA = PA<IUserInitialState['isChangedLevel']>;
export type SetIsUpdateInfoPA = PA<IUserInitialState['isUpdateInfo']>;
export type SetIsLevelUpgradeFromTapPA = PA<IUserInitialState['isLevelUpgradeFromTap']>;
export type SetReferralCodePA = PA<IUserInitialState['referralCode']>;

export type SetFriendsListPA = PA<IUserInitialState['friendsList']>;
export type SetFriendsProfitPA = PA<IUserInitialState['friendsProfit']>;

export type IUserInitialState = {
	selectedRoute: RoutesE;
	currentLevel?: IUserLevels;
	currentLevelId: number;
	balance: number;
	tap: number;
	perHour: number;
	maxEnergy: number;
	energy: number;
	profit: number;
	timeProfit?: ITimeProfit;

	userToken: string | null;
	levels: IUserLevels[];
	profitLevels?: IProfitLevels[];
	isGrabbedProfit: boolean;
	isUpdateInfo: boolean;
	isChangedLevel: boolean;
	isTappet: boolean;
	isLevelUpgradeFromTap: boolean;
	currentProfitLevels: IUserProfitLevels;
	referralCode: string;

	friendsProfit: string;
	friendsList: Array<{ firstName: string; lastName: string }>;
};

export type IUserLevels = {
	id: number;
	level: number;
	maxProfitLevel: number;
	name: string;
	profit: number;
	balanceForUpgrade: number;
};

export type IUserProfitLevels = {
	business: number;
	energy: number;
	investment: number;
	real_estate: number;
	tap: number;
};

export type IProfitLevels = {
	level: number;
	costForUpdate: number;
	category: string;
	profit: number;
};

export type IUserLevel = {
	level: number;
	costForUpdate: number;
	category: string;
	profit: number;
};

export type ITimeProfit = {
	balance: number;
	isLevelUpgrade: boolean;
	levelId: number;
};

export enum RoutesE {
	balance = 'balance',
	upgrade = 'upgrade',
	earn = 'earn',
	friends = 'friends',
	transfer = 'transfer'
}

export enum Devices {
	ios = 'ios',
	android = 'android',
	web = 'web',
	weba = 'weba',
	webk = 'webk',
	macos = 'macos'
}
