import s from './index.module.scss';
import React from 'react';

const img = require('../../assets/images/earn_screen.webp');

export default function EarnPage() {
	return (
		<div className={s.container}>
			<img
				className={s.img}
				src={img}
			/>

			<div className={s.descrBlock}>
				<span className={s.soon}>Soon...</span>

				<span className={s.title}>Complete tasks and earn more coins</span>
				<span className={s.descr}>After a while, the section will be available</span>
			</div>
		</div>
	);
}
