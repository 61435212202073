import { instance } from '..';
import { AxiosResponsePromise } from '@types';

type Taps = (params: {}) => AxiosResponsePromise<any>;
type UpdateProfitLevel = (params: {}) => AxiosResponsePromise<any>;
type TimeProfit = (params: {}) => AxiosResponsePromise<any>;
type UserDataNew = (params: {}) => AxiosResponsePromise<any>;
type Levels = (params: {}) => AxiosResponsePromise<any>;
type ProfitLevels = (params: {}) => AxiosResponsePromise<any>;

type Friends = () => AxiosResponsePromise<any>;
type ProfitFriends = () => AxiosResponsePromise<any>;

interface ITapsService {
	taps: Taps;
	updateProfitLevel: UpdateProfitLevel;
	timeProfit: TimeProfit;
	userDataNew: UserDataNew;
	levels: Levels;
	profitLevels: ProfitLevels;
	profitFriends: ProfitFriends;
	friends: Friends;
}

enum TapsEndpoints {
	taps = '/clicker/tap',
	updateProfitLevel = '/clicker/upgrade-profit-level',
	timeProfit = '/clicker/time-profit',
	userDataNew = '/clicker/data',
	levels = '/clicker/levels',
	profitLevels = '/clicker/profit-levels',

	profitFriends = '/clicker/friends-profit',
	friends = '/clicker/friends'
}

export const TapsService: ITapsService = {
	taps: async (params) => await instance.post(TapsEndpoints.taps, params),
	updateProfitLevel: async (params) =>
		await instance.post(TapsEndpoints.updateProfitLevel, params),
	timeProfit: async (params) => await instance.post(TapsEndpoints.timeProfit, params),
	userDataNew: async (params) => await instance.get(TapsEndpoints.userDataNew, params),
	levels: async (params) => await instance.get(TapsEndpoints.levels, params),
	profitLevels: async (params) => await instance.get(TapsEndpoints.profitLevels, params),

	profitFriends: async () => await instance.get(TapsEndpoints.profitFriends),
	friends: async () => await instance.get(TapsEndpoints.friends)
};
