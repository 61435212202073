import { AuthService } from './AuthService';
import { setAuthToken } from '../../../lib/browser-storage';
import { setUserToken } from '../../../store/reducers/user/dispatchers';
import toast from 'react-hot-toast';

export const signUp = async (
	initData: string,
	successFunc?: (token: string) => void,
	refcode?: string
) => {
	try {
		const { data } = await AuthService.signUp({
			initData: initData,
			refcode: refcode
		});

		if (data?.token) {
			setUserToken(data?.token);
			setAuthToken(data?.token);

			successFunc && successFunc(data?.token);
		}
	} catch (error: any) {
		toast('Login Error!', {
			icon: '❌',
			style: {
				borderRadius: '20px',
				borderColor: 'rgba(216, 220, 235, 0.12)',
				borderWidth: 2,
				background: 'linear-gradient(180deg, #12151C 0%, #050608 100%)',
				color: '#fff',
				width: '100%'
			}
		});
		console.log(error);
	}
};
