import s from './index.module.scss';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../store/reducers/user/selectors';
import { useNavigate } from 'react-router-dom';
import routes from '../../config/routes';
import { formatNumber, renderPersonsImage } from '../../utils';
import classNames from 'classnames';
import { setIsUpdateInfo } from '../../store/reducers/user/dispatchers';
import { LeftArrowIcon } from 'components';

export default function LevelsPage() {
	const { levels, currentLevelId } = useSelector(userSelectors?.userInfo);

	const navigate = useNavigate();

	const navigateHandler = (route: string) => {
		navigate(route);
		window?.scrollTo(0, 0);
	};

	useEffect(() => {
		setIsUpdateInfo(true);
	});

	const { BALANCE_PAGE } = routes;

	return (
		<div className={s.container}>
			<div className={s.titleSection}>
				<span
					className={s.arrow}
					onClick={() => navigateHandler(BALANCE_PAGE)}
				>
					<LeftArrowIcon />
				</span>
				<span className={s.title}>List of levels</span>
			</div>
			{levels &&
				levels.map((level) => (
					<div
						key={level.id}
						className={s.wrapper}
					>
						<div
							className={classNames(s.levelsBorder, {
								[s.selected]: level.id === currentLevelId
							})}
						/>

						<div
							className={classNames(s.levelsBlock, {
								[s.selected]: level.id === currentLevelId
							})}
						>
							{level.id === currentLevelId && (
								<img
									className={s.checkIcon}
									src={require('../../assets/images/check.png')}
								/>
							)}
							<div className={s.row}>
								<img
									className={s.imgAvatar}
									src={renderPersonsImage[level?.id - 1]}
								/>
								<div className={s.levelName}>
									<div className={s.levelNumber}>
										<span className={s.name}>{level.name}</span>
										<div className={s.level}>{level.level} Level</div>
									</div>
									<div className={s.balance}>
										<img src={require('../../assets/images/сoin.png')} />
										<span>{formatNumber(level.balanceForUpgrade)}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
		</div>
	);
}
