import React from 'react';
import s from './index.module.scss';
import {
	EarnIcon,
	EarnSelectedIcon,
	FriendsIcon,
	FriendsSelectedIcon,
	TransferIcon,
	TransferSelectedIcon,
	UpgradeIcon,
	UpgradeSelectedIcon,
	BalanceIcon,
	BalanceSelectedIcon
} from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from 'config/routes';
import classNames from 'classnames';
import { RoutesE } from '../../../types/redux';

const {
	BALANCE_PAGE,
	UPGRADE_PAGE,
	EARN_PAGE,
	FRIENDS_PAGE,
	TRANSFER_PAGE,
	LEVELS_PAGE
} = routes;

const Component = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const renderIcon = (key: string, selected: boolean) => {
		switch (key) {
			case RoutesE.balance:
				return selected ? <BalanceSelectedIcon /> : <BalanceIcon />;
			case RoutesE.upgrade:
				return selected ? <UpgradeSelectedIcon /> : <UpgradeIcon />;
			case RoutesE.earn:
				return selected ? <EarnSelectedIcon /> : <EarnIcon />;
			case RoutesE.friends:
				return selected ? <FriendsSelectedIcon /> : <FriendsIcon />;
			case RoutesE.transfer:
				return selected ? <TransferSelectedIcon /> : <TransferIcon />;
			default:
				return '';
		}
	};

	const navigateHandler = (route: string) => {
		navigate(route);
		window?.scrollTo(0, 0);
	};

	return (
		<>
			{location?.pathname !== LEVELS_PAGE && (
				<div className={s.wrapper}>
					<div className={s.border} />
					<div className={s.container}>
						<div
							onClick={() => navigateHandler(BALANCE_PAGE)}
							className={classNames(s.item, {
								[s.selected]: location?.pathname === BALANCE_PAGE
							})}
						>
							{renderIcon(RoutesE.balance, location?.pathname === BALANCE_PAGE)}

							<span>Balance</span>
						</div>
						<div
							onClick={() => {
								navigateHandler(UPGRADE_PAGE);
							}}
							className={classNames(s.item, {
								[s.selected]: location?.pathname === UPGRADE_PAGE
							})}
						>
							{renderIcon(RoutesE.upgrade, location?.pathname === UPGRADE_PAGE)}

							<span>Upgrade</span>
						</div>
						<div
							onClick={() => navigateHandler(EARN_PAGE)}
							className={classNames(s.item, {
								[s.selected]: location?.pathname === EARN_PAGE
							})}
						>
							{renderIcon(RoutesE.earn, location?.pathname === EARN_PAGE)}

							<span>Earn</span>
						</div>
						<div
							onClick={() => navigateHandler(FRIENDS_PAGE)}
							className={classNames(s.item, {
								[s.selected]: location?.pathname === FRIENDS_PAGE
							})}
						>
							{renderIcon(RoutesE.friends, location?.pathname === FRIENDS_PAGE)}

							<span>Friends</span>
						</div>
						<div
							onClick={() => navigateHandler(TRANSFER_PAGE)}
							className={classNames(s.item, {
								[s.selected]: location?.pathname === TRANSFER_PAGE
							})}
						>
							{renderIcon(RoutesE.transfer, location?.pathname === TRANSFER_PAGE)}

							<span>Transfer</span>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Component;
