import React from 'react';
import { Toast } from 'react-hot-toast';
import { NotificattionContainer } from '../NotificattionContainer';
import s from './index.module.scss';
import { SuccessPopUpIcon } from '../..';

type PropsType = {
	text?: string;
	t: Toast;
};

export const SuccessToast = (props: PropsType) => {
	const { t, text } = props;

	return (
		<NotificattionContainer t={t}>
			<div className={s.container}>
				<SuccessPopUpIcon className={s.icon} />

				<div className={s.titleBlock}>
					<p className={s.text}>{text}</p>
				</div>
			</div>
		</NotificattionContainer>
	);
};
