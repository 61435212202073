import React from 'react';
import { TabBar } from './parts';
import { Route, Routes } from 'react-router-dom';
import { BalancePage, EarnPage, FriendsPage, TransferPage, UpgradePage } from 'pages';
import s from './Navigation.module.scss';
import routes from 'config/routes';
import LevelsPage from '../pages/LevelsPage';
import { Devices } from '../types';

const tele = window?.Telegram.WebApp;
const {
	BALANCE_PAGE,
	EARN_PAGE,
	TRANSFER_PAGE,
	LEVELS_PAGE,
	UPGRADE_PAGE,
	FRIENDS_PAGE,
} = routes;

const Component = () => {

	const isMobileDevice = [Devices.ios, Devices.android].includes(tele.platform);

	return (
		<>
			{isMobileDevice ? (
				<div className={s.container}>
					<Routes>
						<Route
							path={BALANCE_PAGE}
							element={<BalancePage />}
						/>
						<Route
							path={UPGRADE_PAGE}
							element={<UpgradePage />}
						/>
						<Route
							path={EARN_PAGE}
							element={<EarnPage />}
						/>
						<Route
							path={FRIENDS_PAGE}
							element={<FriendsPage />}
						/>
						<Route
							path={TRANSFER_PAGE}
							element={<TransferPage />}
						/>
						<Route
							path={LEVELS_PAGE}
							element={<LevelsPage />}
						/>
					</Routes>

					<TabBar />
				</div>
			) : (
				<div className={s.containerDesktop}>
					<img className={s.telegram} src={require('../assets/images/telegram.webp')} />
					<span className={s.text}>Play on mobile</span>
					<img className={s.qr} src={require('../assets/images/qr.webp')} />
					<span className={s.text}>@Topxtap_bot</span>
				</div>
			)}
		</>
	);
};

export default Component;
