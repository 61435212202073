import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M12 1a5 5 0 0 0-5 5v1H6a5 5 0 0 0-5 5v6a5 5 0 0 0 5 5h6a5 5 0 0 0 5-5v-1h1a5 5 0 0 0 5-5V6a5 5 0 0 0-5-5h-6Zm5 14h1a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3v1h3a5 5 0 0 1 5 5v3ZM3 12a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-6Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;
