import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={32}
		height={32}
		fill='none'
		{...props}
	>
		<g filter='url(#a)'>
			<rect
				width={24}
				height={24}
				x={4}
				y={4}
				fill='#12151C'
				rx={12}
				shapeRendering='crispEdges'
			/>
			<rect
				width={24}
				height={24}
				x={4}
				y={4}
				fill='url(#b)'
				rx={12}
				shapeRendering='crispEdges'
			/>
			<rect
				width={24}
				height={24}
				x={4}
				y={4}
				fill='url(#c)'
				rx={12}
				shapeRendering='crispEdges'
			/>
			<path
				fill='#fff'
				d='M21.707 13.207a1 1 0 0 0-1.414-1.414L14 18.086l-2.293-2.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l7-7Z'
			/>
		</g>
		<defs>
			<linearGradient
				id='b'
				x1={16}
				x2={16}
				y1={4}
				y2={28}
				gradientUnits='userSpaceOnUse'
			>
				<stop stopColor='#F7C619' />
				<stop
					offset={1}
					stopColor='#F78419'
				/>
			</linearGradient>
			<linearGradient
				id='c'
				x1={16.72}
				x2={16.72}
				y1={28}
				y2={4}
				gradientUnits='userSpaceOnUse'
			>
				<stop stopColor='#36CF00' />
				<stop
					offset={1}
					stopColor='#52D800'
				/>
			</linearGradient>
			<filter
				id='a'
				width={32}
				height={32}
				x={0}
				y={0}
				colorInterpolationFilters='sRGB'
				filterUnits='userSpaceOnUse'
			>
				<feFlood
					floodOpacity={0}
					result='BackgroundImageFix'
				/>
				<feColorMatrix
					in='SourceAlpha'
					result='hardAlpha'
					values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
				/>
				<feOffset />
				<feGaussianBlur stdDeviation={2} />
				<feComposite
					in2='hardAlpha'
					operator='out'
				/>
				<feColorMatrix values='0 0 0 0 0.211765 0 0 0 0 0.811765 0 0 0 0 0 0 0 0 1 0' />
				<feBlend
					in2='BackgroundImageFix'
					result='effect1_dropShadow_2114_2010'
				/>
				<feBlend
					in='SourceGraphic'
					in2='effect1_dropShadow_2114_2010'
					result='shape'
				/>
			</filter>
		</defs>
	</svg>
);

export default SvgComponent;
