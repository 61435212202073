const imagesPerson = [
	require('../assets/images/person_level_1.webp'),
	require('../assets/images/person_level_2.webp'),
	require('../assets/images/person_level_3.webp'),
	require('../assets/images/person_level_4.webp'),
	require('../assets/images/person_level_5.webp'),
	require('../assets/images/person_level_6.webp')
];

export default imagesPerson;
