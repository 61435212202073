import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={28}
		height={28}
		fill='none'
		{...props}
	>
		<rect
			width={28}
			height={28}
			fill='#D8DCEB'
			fillOpacity={0.15}
			rx={14}
		/>
		<path
			fill='#CBCBCC'
			d='M9.923 8.744a.833.833 0 1 0-1.179 1.179L12.822 14l-4.078 4.077a.833.833 0 1 0 1.179 1.179L14 15.178l4.077 4.078a.833.833 0 1 0 1.179-1.179L15.178 14l4.078-4.077a.833.833 0 1 0-1.179-1.179L14 12.822 9.923 8.744Z'
		/>
	</svg>
);

export default SvgComponent;
