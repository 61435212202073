import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={28}
		fill='none'
		{...props}
	>
		<g filter='url(#b)'>
			<path
				fill='#FFE194'
				d='m5.206 13.555 8.903-9.19c.688-.71 1.879-.098 1.702.875l-.86 4.725a1 1 0 0 0 .648 1.12l3.388 1.21a1 1 0 0 1 .404 1.615l-8.524 9.376c-.685.754-1.927.12-1.719-.876l1.056-5.069a1 1 0 0 0-.736-1.174l-3.786-.947a1 1 0 0 1-.476-1.665Z'
			/>
		</g>
		<defs>
			<filter
				id='b'
				width={22.727}
				height={27.561}
				x={0.924}
				y={0.057}
				colorInterpolationFilters='sRGB'
				filterUnits='userSpaceOnUse'
			>
				<feFlood
					floodOpacity={0}
					result='BackgroundImageFix'
				/>
				<feColorMatrix
					in='SourceAlpha'
					result='hardAlpha'
					values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
				/>
				<feOffset />
				<feGaussianBlur stdDeviation={2} />
				<feComposite
					in2='hardAlpha'
					operator='out'
				/>
				<feColorMatrix values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0' />
				<feBlend
					in2='BackgroundImageFix'
					result='effect1_dropShadow_1179_244'
				/>
				<feBlend
					in='SourceGraphic'
					in2='effect1_dropShadow_1179_244'
					result='shape'
				/>
			</filter>
		</defs>
	</svg>
);

export default SvgComponent;
