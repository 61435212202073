import {
	IUserInitialState,
	SetBalancePA,
	SetEnergyPA,
	SetCurrentProfitLevelsPA,
	SetLevelsPA,
	SetMaxEnergyPA,
	SetProfitLevelsPA,
	SetProfitPA,
	SetTapPA,
	SetUserTokenPA,
	SetCurrentLevelIdPA,
	SetTimeProfitPA,
	SetGrabbedTimeProfitPA,
	SetIsUpdateInfoPA,
	SetReferralCodePA,
	SetIsChangedLevelPA,
	SetIsTappetPA,
	SetFriendsListPA,
	SetFriendsProfitPA
} from '@types';

export const increaseBalanceByTap = (state: IUserInitialState) => {
	state.balance = state.balance + state.tap;
	state.energy = state.energy - state.tap;
};

export const setUserToken = (state: IUserInitialState, actions: SetUserTokenPA) => {
	state.userToken = actions?.payload;
};

export const setCurrentUserProfitLevels = (
	state: IUserInitialState,
	actions: SetCurrentProfitLevelsPA
) => {
	state.currentProfitLevels = actions?.payload;
};

export const setCurrentUserLevelId = (
	state: IUserInitialState,
	actions: SetCurrentLevelIdPA
) => {
	state.currentLevelId = actions?.payload;
};

export const setUserEnergy = (state: IUserInitialState, actions: SetEnergyPA) => {
	state.energy = actions?.payload;
};

export const setUserMaxEnergy = (state: IUserInitialState, actions: SetMaxEnergyPA) => {
	state.maxEnergy = actions?.payload;
};

export const setUserBalance = (state: IUserInitialState, actions: SetBalancePA) => {
	state.balance = actions?.payload;
};

export const setUserProfit = (state: IUserInitialState, actions: SetProfitPA) => {
	state.profit = actions?.payload;
};

export const setUserTap = (state: IUserInitialState, actions: SetTapPA) => {
	state.tap = actions?.payload;
};

export const setLevels = (state: IUserInitialState, actions: SetLevelsPA) => {
	state.levels = actions?.payload;
};

export const setProfitLevels = (state: IUserInitialState, actions: SetProfitLevelsPA) => {
	state.profitLevels = actions?.payload;
};

export const setTimeProfit = (state: IUserInitialState, actions: SetTimeProfitPA) => {
	state.timeProfit = actions?.payload;
};

export const setGrabbedTimeProfit = (
	state: IUserInitialState,
	actions: SetGrabbedTimeProfitPA
) => {
	state.isGrabbedProfit = actions?.payload;
};

export const setIsUpdateInfo = (state: IUserInitialState, actions: SetIsUpdateInfoPA) => {
	state.isUpdateInfo = actions?.payload;
};

export const setIsChangedLevel = (
	state: IUserInitialState,
	actions: SetIsChangedLevelPA
) => {
	state.isChangedLevel = actions?.payload;
};

export const setIsTappet = (state: IUserInitialState, actions: SetIsTappetPA) => {
	state.isTappet = actions?.payload;
};

export const setIsLevelUpgradeFromTap = (
	state: IUserInitialState,
	actions: SetIsUpdateInfoPA
) => {
	state.isLevelUpgradeFromTap = actions?.payload;
};

export const setReferralCode = (state: IUserInitialState, actions: SetReferralCodePA) => {
	state.referralCode = actions?.payload;
};

export const setFriendsList = (state: IUserInitialState, actions: SetFriendsListPA) => {
	state.friendsList = actions?.payload;
};

export const setFriendsProfit = (
	state: IUserInitialState,
	actions: SetFriendsProfitPA
) => {
	state.friendsProfit = actions?.payload;
};
