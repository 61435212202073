import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path d="M19.1742 4.58363L9.35365 4.58363C9.10793 4.58363 8.93442 4.3429 9.01212 4.10979L9.5874 2.38398C9.7344 1.94296 9.23131 1.57139 8.85303 1.84159L2.20101 6.59304C1.933 6.78448 1.933 7.18279 2.20101 7.37423L8.85303 12.1257C9.23132 12.3959 9.7344 12.0243 9.5874 11.5833L9.01212 9.85748C8.93442 9.62437 9.10793 9.38364 9.35365 9.38364H19.1742C20.102 9.38364 20.8542 8.63147 20.8542 7.70364V6.26363C20.8542 5.3358 20.102 4.58363 19.1742 4.58363Z" fill="url(#paint0_linear_1498_7055)" stroke="#73777F"/>
		<path d="M7.18 19.6336H13.4005C13.6462 19.6336 13.8198 19.8744 13.7421 20.1075L13.1668 21.8333C13.0198 22.2743 13.5229 22.6459 13.9011 22.3757L20.5532 17.6242C20.8212 17.4328 20.8212 17.0345 20.5532 16.843L13.9011 12.0916C13.5229 11.8214 13.0198 12.193 13.1668 12.634L13.7421 14.3598C13.8198 14.5929 13.6462 14.8336 13.4005 14.8336L7.18 14.8336C6.25216 14.8336 5.5 15.5858 5.5 16.5136L5.5 17.9536C5.5 18.8815 6.25216 19.6336 7.18 19.6336Z" fill="url(#paint1_linear_1498_7055)" stroke="#73777F"/>
		<defs>
		<linearGradient id="paint0_linear_1498_7055" x1="11.4271" y1="1.75" x2="11.4271" y2="12.2173" gradientUnits="userSpaceOnUse">
		<stop stopColor="#73777F"/>
		<stop offset="1" stopColor="#424448"/>
		</linearGradient>
		<linearGradient id="paint1_linear_1498_7055" x1="13.1271" y1="12" x2="13.1271" y2="22.4673" gradientUnits="userSpaceOnUse">
		<stop stopColor="#73777F"/>
		<stop offset="1" stopColor="#424448"/>
		</linearGradient>
		</defs>
	</svg>
);

export default SvgComponent;
