import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path d="M19.2 4.7999L12 2.3999L4.8 4.7999L3 10.7999C3 10.7999 7.89682 16.5013 11.6091 21.1128C11.81 21.3624 12.19 21.3624 12.3909 21.1128C16.1032 16.5013 21 10.7999 21 10.7999L19.2 4.7999Z" fill="url(#paint0_linear_1498_7059)"/>
		<path d="M19.2 4.7999L15.6 10.7999M15.6 10.7999L12.4596 3.47224C12.2864 3.06819 11.7136 3.06819 11.5404 3.47224L8.4 10.7999M15.6 10.7999C15.6 10.7999 14.1431 15.9619 12.4524 20.4352C12.2929 20.8574 11.7071 20.8574 11.5476 20.4352C9.85687 15.9619 8.4 10.7999 8.4 10.7999M15.6 10.7999H18.3056H21M8.4 10.7999H3M8.4 10.7999L4.8 4.7999" stroke="#73777F" strokeLinejoin="round"/>
		<path d="M18.7076 4.63576L12.3162 2.50531C12.111 2.43689 11.889 2.43689 11.6838 2.50531L5.29244 4.63576C4.98371 4.73867 4.74436 4.98538 4.65084 5.29709L3.15718 10.276C3.05924 10.6024 3.13401 10.956 3.35551 11.215C4.49679 12.5499 8.17106 16.864 11.2166 20.6266C11.6198 21.1247 12.3802 21.1247 12.7834 20.6266C15.8289 16.864 19.5032 12.5499 20.6445 11.215C20.866 10.956 20.9408 10.6024 20.8428 10.276L19.3492 5.29709C19.2556 4.98538 19.0163 4.73867 18.7076 4.63576Z" stroke="#73777F" strokeLinejoin="round"/>
		<defs>
			<linearGradient id="paint0_linear_1498_7059" x1="12" y1="2.3999" x2="12" y2="21.5999" gradientUnits="userSpaceOnUse">
			<stop stopColor="#73777F"/>
			<stop offset="1" stopColor="#424448"/>
			</linearGradient>
		</defs>
	</svg>
);

export default SvgComponent;
