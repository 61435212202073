import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import s from './index.module.scss';
import TapElements from './parts/TapElements';
import { userSelectors } from 'store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import { taps } from '../../../lib/api/taps';
import { TapZoneBg } from '../../../components/svg';
import { userInfoFromBackend } from '../../../lib/api/taps/taps';
import { renderPersonsImage, renderTapZoneImage } from '../../../utils';

const tele = window?.Telegram.WebApp;

export default function TapZoneComponent() {
	const [isClicked, setIsClicked] = useState<boolean>(false);

	const { tap, energy, currentLevelId, balance, userToken } = useSelector(
		userSelectors.userInfo
	);

	const [clickItems, setClickItems] = useState<
		Array<{ id: number; taps: { ts: string }; coord: { x: number; y: number } }>
	>([]);

	const clickHandler = (e: React.TouchEvent<HTMLDivElement>) => {
		tele?.HapticFeedback?.impactOccurred('heavy');

		if (energy && energy >= tap) {
			const newTouches = Array.from(e?.touches).map((touch) => {
				const timestamp = Date.now();

				return {
					id: timestamp,
					taps: { ts: new Date(timestamp).toISOString() },
					coord: { x: touch?.clientX, y: touch?.clientY }
				};
			});

			const allTouches = Array.from(clickItems).map((touch) => touch.taps);

			setIsClicked(true);

			if (Date.now() - clickItems[0]?.id > 500) {
				taps(allTouches);
				setClickItems([...newTouches]);
			} else {
				setClickItems((i) => [...i, ...newTouches]);
			}

			setTimeout(() => setIsClicked(false), 500);
		}
	};

	useEffect(() => {
		let interval = null;

		if (userToken) {
			if (isClicked) return;

			interval = setInterval(
				() => {
					if (!isClicked) {
						if (clickItems.length) {
							const allTouches = Array.from(clickItems).map((touch) => touch.taps);

							taps(allTouches);
							setClickItems([]);
						} else {
							userInfoFromBackend();
						}
					}
				},
				clickItems.length ? 200 : 900
			);
		}

		//Clearing the interval
		// eslint-disable-next-line consistent-return
		return () => clearInterval(interval || undefined);
	}, [balance, energy, userToken]);

	const deleteClickItemsHandler = (id: number) => {
		const newArr = clickItems?.filter((e) => e?.id !== id);

		setClickItems(newArr);
	};

	return (
		<div
			onTouchStart={clickHandler}
			className={cn(s.container, { [s.clicked]: isClicked && energy && energy > tap })}
		>
			<TapZoneBg opacity={0.5} />
			<img
				className={s.person}
				src={renderTapZoneImage[currentLevelId - 1]}
			/>

			<img
				className={s.img}
				src={renderPersonsImage[currentLevelId - 1]}
			/>

			{clickItems?.map((e) => (
				<TapElements
					id={e?.id}
					key={e?.id}
					coord={e?.coord}
					deleteItemHandler={() => {
						// taps(clickItems);
						deleteClickItemsHandler(e?.id);
					}}
				/>
			))}
		</div>
	);
}
