import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={12}
		height={12}
		fill='none'
		{...props}
	>
		<path
			stroke='#fff'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeOpacity={0.5}
			strokeWidth={1.5}
			d='m4 3 4 4-4 4'
		/>
	</svg>
);

export default SvgComponent;
