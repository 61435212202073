import React, { useEffect, useState } from 'react';
import { userSelectors } from 'store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import { LevelIcon, Shevron } from 'components';
import s from './index.module.scss';
import { formatNumberThouthands } from 'utils';
import { useNavigate } from 'react-router-dom';
import routes from 'config/routes';
import { setIsUpdateInfo } from '../../../store/reducers/user/dispatchers';

export default function LevelProgressComponent() {
	const navigate = useNavigate();

	const navigateHandler = (route: string) => {
		navigate(route);
		window?.scrollTo(0, 0);
	};
	const [stateLevel, setStateLevel] = useState('');
	const [nextLevelBalance, setNextLevelBalance] = useState(0);

	const { LEVELS_PAGE } = routes;

	const { balance, levels, currentLevel, isUpdateInfo, currentLevelId } = useSelector(userSelectors.userInfo);

	useEffect(() => {
		// eslint-disable-next-line consistent-return
		// eslint-disable-next-line max-len
		const currentLevelName = levels && levels.filter((levelFromBackend) => levelFromBackend.level === currentLevelId).map((e) => e.name)[0];

		if (!currentLevelName) return;

		setStateLevel(currentLevelName && currentLevelName);
		setIsUpdateInfo(false);
		// eslint-disable-next-line max-len
		setNextLevelBalance(levels && levels.filter((levelFromBackend) => levelFromBackend.level === (currentLevelId && currentLevelId + 1)).map((e) => e.balanceForUpgrade)[0]);
	}, [balance, levels, currentLevel, isUpdateInfo, currentLevelId]);

	const nextLevelBalanceProgress = currentLevelId === 6 && !nextLevelBalance ? 50000000 : nextLevelBalance;

	return (
		<div className={s.container}>
			<div className={s.progressBar}>
				<div
					className={s.progressLine}
					style={{ width: `${(balance * 100) / nextLevelBalanceProgress}%` }}
				/>
			</div>

			<div className={s.levelInfo}>
				<div className={s.level} onClick={() => navigateHandler(LEVELS_PAGE)}>
					<LevelIcon />
					{stateLevel}
					<Shevron />
				</div>

				<div className={s.nextLevel}>
					{currentLevelId === 6
						? <span>Max level</span>
						: 
							<>
								<span>{formatNumberThouthands(nextLevelBalance && nextLevelBalance)}</span>
								<span>to next level</span>
							</>
					}
				</div>
			</div>
		</div>
	);
}
