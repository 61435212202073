import s from './index.module.scss';
import React from 'react';

const img = require('../../assets/images/transfer_screen.webp');

export default function EarnPage() {
	return (
		<div className={s.container}>
			<div className={s.titleSection}>
				<span className={s.soon}>Soon...</span>
				<span className={s.title}>Exchange earned coins for REAL MONEY</span>
			</div>

			<img
				className={s.img}
				src={img}
			/>

			<div className={s.descr}>
				After a while, we will announce the value of the game currency and you will be
				able to exchange the earned coins for real money. Therefore, have time to earn as
				many coins as possible before we announce the price.
			</div>
		</div>
	);
}
