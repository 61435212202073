export enum UrlQueries {
	refcode = 'refcode'
}

export type GetStringQueryFromUrl = (query: UrlQueries) => string | null;

export const getStringQueryFromUrl: GetStringQueryFromUrl = (query) => {
	const urlParams = new URLSearchParams(window.location.search);

	return urlParams.get(query);
};
