import React from 'react';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';
import { MainContent } from './components';
import Navigation from 'navigation/Navigation';
import { Toaster } from 'react-hot-toast';

const tele = window?.Telegram.WebApp;

function App() {
	useEffect(() => {
		tele?.ready();
	});

	useEffect(() => {
		if (!tele.isExpanded) tele?.expand();
	}, []);

	return (
		<Provider store={store}>
			<BrowserRouter>
				<MainContent>
					<Navigation />
				</MainContent>
			</BrowserRouter>

			<Toaster
				position='top-center'
				reverseOrder={false}
				containerStyle={{ zIndex: 10001, bottom: 105 }}
				toastOptions={{ className: 'toastClass' }}
				gutter={10}
			/>
		</Provider>
	);
}

export default App;
