import { configureStore, EnhancedStore } from '@reduxjs/toolkit';

import { reducer } from './reducers';

import { StoreInner } from '@types';

export const store: EnhancedStore<StoreInner> = configureStore({
	reducer,
	devTools: true
});
