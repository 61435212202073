import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={20}
		height={20}
		fill='none'
		{...props}
	>
		<path
			fill='url(#a2)'
			d='M16 17c-1.2 1.2-3.53 1-6 1s-4.8.2-6-1c-.96-.96-1.985-6.632-2.612-10.498a.747.747 0 0 1 .995-.82L5.617 6.86a.5.5 0 0 0 .597-.207L9.36 1.538a.75.75 0 0 1 1.278 0l3.147 5.115a.5.5 0 0 0 .597.207l3.234-1.178a.747.747 0 0 1 .995.82C17.985 10.368 16.96 16.04 16 17Z'
		/>
		<path
			fill='url(#b2)'
			d='M16 17c-1.2 1.2-3.53 1-6 1s-4.8.2-6-1c.333-.333 2-1 6-1s5.667.667 6 1Z'
		/>
		<path
			fill='url(#c2)'
			d='M8.988 6.784a1 1 0 0 1 1.68 0l1.617 2.502a1 1 0 0 1 0 1.085l-1.617 2.502a1 1 0 0 1-1.68 0L7.371 10.37a1 1 0 0 1 0-1.085l1.617-2.502Z'
		/>
		<defs>
			<linearGradient
				id='a2'
				x1={14.409}
				x2={5.3}
				y1={1}
				y2={18.852}
				gradientUnits='userSpaceOnUse'
			>
				<stop stopColor='#FFF500' />
				<stop
					offset={1}
					stopColor='#FF6B00'
				/>
			</linearGradient>
			<linearGradient
				id='b2'
				x1={10.565}
				x2={10.636}
				y1={16}
				y2={18.704}
				gradientUnits='userSpaceOnUse'
			>
				<stop stopColor='#EE4A04' />
				<stop
					offset={1}
					stopColor='#FFA800'
				/>
			</linearGradient>
			<linearGradient
				id='c2'
				x1={8.752}
				x2={12.304}
				y1={13.949}
				y2={11.359}
				gradientUnits='userSpaceOnUse'
			>
				<stop stopColor='#EE4A04' />
				<stop
					offset={1}
					stopColor='#FFA800'
				/>
			</linearGradient>
		</defs>
	</svg>
);

export default SvgComponent;
