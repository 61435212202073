import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={24}
		height={24}
		fill='none'
		{...props}
	>
		<path d="M8.44629 22.7999C1.20285 22.7999 3.50961 14.7265 6.21759 10.934C6.83843 10.0645 7.86403 9.59995 8.93244 9.59995H9.64632C7.72638 7.20003 10.2464 7.19999 12.0463 7.19995C13.8462 7.19999 16.3663 7.20003 14.4464 9.59995H15.1603C16.2287 9.59995 17.2543 10.0645 17.8751 10.934C20.5831 14.7265 22.8899 22.7999 15.6464 22.7999C15.6464 22.7999 13.4716 23.4 12.0463 23.4C10.621 23.3999 8.44629 22.7999 8.44629 22.7999Z" fill="url(#paint0_linear_1498_7053)"/>
		<path d="M9.64632 9.59995H8.93244C7.86403 9.59995 6.83843 10.0645 6.21759 10.934C3.50961 14.7265 1.20285 22.7999 8.44629 22.7999C8.44629 22.7999 10.621 23.3999 12.0463 23.4C13.4716 23.4 15.6464 22.7999 15.6464 22.7999C22.8899 22.7999 20.5831 14.7265 17.8751 10.934C17.2543 10.0645 16.2287 9.59995 15.1603 9.59995H14.4464M9.64632 9.59995C7.72638 7.20003 10.2464 7.19999 12.0463 7.19995C13.8462 7.19999 16.3663 7.20003 14.4464 9.59995M9.64632 9.59995C9.64632 9.59995 11.0802 10.1999 12.0463 10.2M14.4464 9.59995C14.4464 9.59995 13.0124 10.2 12.0463 10.2M12.0463 10.2C12.0463 10.2 12.9 13.4 15.6464 14.5M12.0463 10.2C12.0463 10.2 11.5 16 8 17.5" stroke="#F7D319" strokeLinecap="round"/>
		<path d="M18.7621 6.9177C18.0096 6.26803 18.2774 4.72472 19.3602 3.47064C20.443 2.21655 21.9308 1.72658 22.6832 2.37625C23.4357 3.02593 23.1679 4.56923 22.0851 5.82332C21.0023 7.0774 19.5145 7.56737 18.7621 6.9177Z" fill="url(#paint1_linear_1498_7053)" stroke="#F7D319"/>
		<path d="M2.07979 1.88908C2.8973 0.84573 4.82873 0.994009 6.39376 2.22027C7.95879 3.44654 8.56477 5.28642 7.74726 6.32978C6.92975 7.37313 4.99832 7.22485 3.4333 5.99859C1.86827 4.77232 1.26228 2.93243 2.07979 1.88908Z" fill="url(#paint2_linear_1498_7053)" stroke="#F7D319"/>
		<defs>
		<linearGradient id="paint0_linear_1498_7053" x1="12.0464" y1="7.19995" x2="12.0464" y2="23.4" gradientUnits="userSpaceOnUse">
		<stop stopColor="#F7D319"/>
		<stop offset="1" stopColor="#F78419"/>
		</linearGradient>
		<linearGradient id="paint1_linear_1498_7053" x1="20.7226" y1="2.08911" x2="20.7226" y2="7.20484" gradientUnits="userSpaceOnUse">
		<stop stopColor="#F7D319"/>
		<stop offset="1" stopColor="#F78419"/>
		</linearGradient>
		<linearGradient id="paint2_linear_1498_7053" x1="4.91353" y1="1.19409" x2="4.91353" y2="7.02477" gradientUnits="userSpaceOnUse">
		<stop stopColor="#F7D319"/>
		<stop offset="1" stopColor="#F78419"/>
		</linearGradient>
		</defs>
	</svg>
);

export default SvgComponent;
